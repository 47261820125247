*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Helvetica', sans-serif; */
  ::-webkit-scrollbar {
    width: 10px;               /* width of the entire scrollbar */
  };
  
  ::-webkit-scrollbar-track {
    background: #c1bebe;        /* color of the tracking area */
  };
  
  ::-webkit-scrollbar-thumb {
    background-color: #878585;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 2px solid #c1bebe;  /* creates padding around scroll thumb */
  }
}